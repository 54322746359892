import cn from 'classnames';
import { FC } from 'react';

import { ReactComponent as Cancel } from '@app/pmi/src/assets/media/V2/v2_cancel_white.svg';
import { ReactComponent as Tick } from '@app/pmi/src/assets/media/V2/v2_tick_white.svg';

type TCTAIcon = 'check' | 'close' | 'none';

interface Props {
  btnText: string | JSX.Element;
  isDark: boolean;
  onBtnClick?: () => void;
  className?: string;
  icon?: TCTAIcon;
  isDisabled?: boolean;
}

export const Button: FC<Props> = ({ isDark, btnText, onBtnClick, className, icon, isDisabled = false }) => {
  const getCurrentIcon = iconName => {
    let currentIcon;

    const currentTickIcon = isDark ? <Tick /> : <Tick className={cn('isDark')} />;
    const currentCancelIcon = isDark ? <Cancel /> : <Cancel className={cn('isDark')} />;

    switch (iconName) {
      case 'check':
        currentIcon = currentTickIcon;
        break;
      case 'close':
        currentIcon = currentCancelIcon;
        break;
      default:
    }

    return currentIcon;
  };

  return (
    <button
      disabled={isDisabled}
      type="button"
      className={cn('ta-v2-cta-btn', className, {
        'ta-v2-cta-btn-dark': isDark,
      })}
      onClick={onBtnClick}
    >
      {icon && icon !== 'none' && <span className="ta-v2-cta-btn-icon">{getCurrentIcon(icon)}</span>}

      <span className="font-ta-v2-global-body-large">{btnText}</span>
    </button>
  );
};
