import cn from 'classnames';

import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import { LocaleFragment } from '@lib/tools/locale/views/LocaleFragment';

import { Button } from '@components/pmi/src/atoms/CTA/Button';
import { ButtonOutlined } from '@components/pmi/src/atoms/CTA/ButtonOutlined';

interface Props {
  isNextButtonEnabled: boolean;
  handleClick: () => void;
}

export const QuizFooter = ({ handleClick, isNextButtonEnabled }: Props) => {
  return (
    <div className="ta-v2-quiz-footer">
      {isNextButtonEnabled ? (
        <Button
          isDark
          btnText={<LocaleFragment message={localeV2.common.nextQuestion} />}
          className={cn('ta-v2-quiz-footer-next-button', {
            'add-outline': !isNextButtonEnabled,
          })}
          onBtnClick={handleClick}
        />
      ) : (
        <ButtonOutlined
          isDark
          btnText={<LocaleFragment message={localeV2.common.nextQuestion} />}
          disabled={!isNextButtonEnabled}
          className={cn('ta-v2-quiz-footer-next-button', {
            'add-outline': !isNextButtonEnabled,
          })}
        />
      )}
    </div>
  );
};
