import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import arrowIcon from '@app/pmi/src/assets/media/V2/v2_arrow_slate.png';
import veevDevice from '@app/pmi/src/assets/media/V2/v2_veev_device.png';
import imgBackground from '@app/pmi/src/assets/media/V2/v2_veev_vortex.png';
import imgBackgroundSmall from '@app/pmi/src/assets/media/V2/v2_veev_vortex_small.png';

import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import { LocaleFragment } from '@lib/tools/locale/views/LocaleFragment';

export const VeevBanner: FC = () => {
  const isMobileResolution = useMediaQuery({ maxWidth: 767 });
  return (
    <div
      className="ta-v2-veev-banner"
      style={{ backgroundImage: isMobileResolution ? `url(${imgBackgroundSmall})` : `url(${imgBackground})` }}
    >
      <img alt="Device" className="ta-v2-veev-banner-device" src={veevDevice} />
      <div className="ta-v2-veev-banner-content">
        <div className="ta-v2-veev-banner-content-text font-ta-v2-title-6">
          <LocaleFragment message={localeV2.result.veevBannerText} />
        </div>
        <div className="ta-v2-veev-banner-content-icon" style={{ backgroundImage: `url(${arrowIcon})` }} />
      </div>
    </div>
  );
};
