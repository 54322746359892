import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { localeCommon } from '@lib/tools/locale/source/pmi/common';
import { LocaleFragment } from '@lib/tools/locale/views/LocaleFragment';

import { LanguageSwitcherComponent } from '@components/pmi/src/molecules/LanguageSwitcher';
import { checkCurrentPrimaryColor } from '@components/pmi/src/utils';

interface Props {
  handleClose: () => void;
}

export const HealthWarningPrePage: FC<Props> = ({ handleClose }) => {
  const isMobileResolution = useMediaQuery({ maxWidth: 767 });
  const currentPrimaryColor = checkCurrentPrimaryColor();
  const { retailerLanguages } = useRetailer();

  return (
    <div className="health-warning-prepage">
      <div className="health-warning-prepage-header" />

      <div className="health-warning-prepage-body">
        <LanguageSwitcherComponent
          className="health-warning-language-switcher-wrapper"
          supportedLanguages={[...retailerLanguages].reverse()}
        />

        <div
          className={`health-warning ${isMobileResolution ? 'font-ta-v2-global-regulatory-text-medium' : 'font-ta-v2-custom-large-paragraph'}`}
        >
          <LocaleFragment message={localeCommon.healthWarning.retailerPrepageHealthWarning} />
        </div>

        <button
          className={`agree-button ${isMobileResolution ? 'font-ta-v2-custom-small-header-bold' : 'font-ta-v2-custom-large-paragraph-bold'}`}
          style={{ backgroundColor: currentPrimaryColor }}
          type="button"
          onClick={handleClose}
        >
          <LocaleFragment message={localeCommon.healthWarning.retailerPrepageAgreeBtnText} />
        </button>
      </div>
    </div>
  );
};
