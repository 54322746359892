import classNames from 'classnames';
import { FC, useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { ReactComponent as ArrowIcon } from '@app/pmi/src/assets/media/arrow.svg';

import { localeCommon } from '@lib/tools/locale/source/pmi/common';
import { LocaleFragment } from '@lib/tools/locale/views/LocaleFragment';
import { useTypedSelector } from '@lib/tools/views/hooks';

import { calculateAge } from '@components/pmi/src/utils';
import { AGE_CONFIRMED } from '@components/pmi/src/utils/consts';

/* ------------ MONTH DROPDOWN ------------ */
interface MonthPropsType {
  value: number;
  label: null | string;
}

interface MonthDropdownPropsType {
  month: MonthPropsType;
  setMonth: (item: MonthPropsType) => void;
}

export const MonthDropdown: FC<MonthDropdownPropsType> = ({ month, setMonth }) => {
  const monthArr = [
    { label: <LocaleFragment message={localeCommon.ageGate.january} />, value: 1 },
    { label: <LocaleFragment message={localeCommon.ageGate.february} />, value: 2 },
    { label: <LocaleFragment message={localeCommon.ageGate.march} />, value: 3 },
    { label: <LocaleFragment message={localeCommon.ageGate.april} />, value: 4 },
    { label: <LocaleFragment message={localeCommon.ageGate.may} />, value: 5 },
    { label: <LocaleFragment message={localeCommon.ageGate.june} />, value: 6 },
    { label: <LocaleFragment message={localeCommon.ageGate.july} />, value: 7 },
    { label: <LocaleFragment message={localeCommon.ageGate.august} />, value: 8 },
    { label: <LocaleFragment message={localeCommon.ageGate.september} />, value: 9 },
    { label: <LocaleFragment message={localeCommon.ageGate.october} />, value: 10 },
    { label: <LocaleFragment message={localeCommon.ageGate.november} />, value: 11 },
    { label: <LocaleFragment message={localeCommon.ageGate.december} />, value: 12 },
  ];

  const [isVisible, setIsVisible] = useState(false);

  const handleChangeMonth = useCallback(
    item => {
      setMonth(item);
    },
    [setMonth],
  );

  const choseMonth = useCallback(item => {
    handleChangeMonth(item);
    setIsVisible(false);
  }, []);

  return (
    <div className={`font-ta-v2-custom-small-header-bold dropdown ${isVisible ? 'open' : ''}`}>
      <button className="dropdown-toggle" type="submit" onClick={() => setIsVisible(!isVisible)}>
        {month.label || <LocaleFragment message={localeCommon.ageGate.month} />}
        <ArrowIcon
          className={classNames('toggle-icon', {
            open: isVisible,
          })}
        />
      </button>
      <div className="dropdown-menu font-ta-v2-custom-small-header-bold">
        {monthArr.map(item => (
          <button
            key={item.value}
            type="button"
            value={item.value}
            onClick={() => {
              choseMonth(item);
            }}
            onKeyDown={() => {
              choseMonth(item);
            }}
          >
            {item.label}
          </button>
        ))}
      </div>
    </div>
  );
};

/* ------------ YEAR DROPDOWN ------------ */
interface YearDropdownPropsType {
  year: number;
  setYear: (item: number) => void;
}

export const YearDropdown: FC<YearDropdownPropsType> = ({ year, setYear }) => {
  const currentYear = new Date().getUTCFullYear();
  const years = Array(currentYear - (currentYear - 101)).fill('');

  const [isVisible, setIsVisible] = useState(false);

  const handleChangeYear = useCallback(
    item => {
      setYear(item);
    },
    [setYear],
  );

  const choseYear = useCallback(value => {
    handleChangeYear(currentYear - value);
    setIsVisible(false);
  }, []);

  return (
    <div className={`font-ta-v2-custom-small-header-bold dropdown ${isVisible ? 'open' : ''}`}>
      <button className="dropdown-toggle" type="button" onClick={() => setIsVisible(!isVisible)}>
        {year || <LocaleFragment message={localeCommon.ageGate.year} />}
        <ArrowIcon
          className={classNames('toggle-icon', {
            open: isVisible,
          })}
        />
      </button>
      <div className="dropdown-menu font-ta-v2-custom-small-header-bold">
        {/* @todo item is never used. It may be an issue & item is bad name */}
        {years.map((_item, index) => (
          <button
            key={index}
            type="button"
            value={currentYear - index}
            onClick={() => {
              choseYear(index);
            }}
            onKeyDown={() => {
              choseYear(index);
            }}
          >
            <p>{currentYear - index}</p>
          </button>
        ))}
      </div>
    </div>
  );
};

interface Props {
  handleUpdateCurrentAgeConfirmedValue: () => void;
}

export const AgeGate: FC<Props> = ({ handleUpdateCurrentAgeConfirmedValue }) => {
  const [inputMonth, setInputMonth] = useState<MonthPropsType>({ label: null, value: new Date().getMonth() });
  const [inputYear, setInputYear] = useState<number>(null as any);
  const [ageGate, setAgeGate] = useState<boolean>(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(true);
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const ageLimit = useTypedSelector(state => state.retailer?.data?.tags?.age_gate_limit || '18');
  const isMobileResolution = useMediaQuery({ maxWidth: 767 });

  const ageConfirmedFromLocalStorage = localStorage.getItem(AGE_CONFIRMED);

  const checkAge = useCallback(() => {
    const age = calculateAge(inputYear, inputMonth.value) >= +ageLimit;
    setAgeGate(age);
    setIsBlocked(!age);
  }, [inputYear, inputMonth.value]);

  useEffect(() => {
    if (ageGate) {
      localStorage.setItem(AGE_CONFIRMED, JSON.stringify(true));
      handleUpdateCurrentAgeConfirmedValue();
    }
  }, [ageGate, ageConfirmedFromLocalStorage]);

  useEffect(() => {
    setIsBtnDisabled(!(inputMonth.value && inputYear));
  }, [inputMonth, inputYear]);

  return (
    <div className="tasteAdvisor-gate">
      <div className="d-flex flex-column align-center text-center popup w-100vw h-100vh">
        {isBlocked ? (
          <div
            className={`d-flex text-title mx-auto ${isMobileResolution ? 'font-ta-v2-global-regulatory-text-small' : 'font-ta-v2-global-body-small'}`}
          >
            <p className="mb-0">
              <LocaleFragment message={localeCommon.ageGate.accessBlocked} options={{ ageLimit }} />
            </p>
          </div>
        ) : (
          <div className="d-flex flex-column align-center">
            <div
              className={`d-flex text-title mx-auto ${isMobileResolution ? 'font-ta-v2-global-regulatory-text-small' : 'font-ta-v2-global-body-small'}`}
            >
              <p className="mb-0">
                <LocaleFragment message={localeCommon.ageGate.titleOne} options={{ ageLimit }} />
              </p>
              <p className="mx-auto mb-0">
                <LocaleFragment message={localeCommon.ageGate.titleTwo} />
              </p>
            </div>
            <div className="d-flex justify-content-center margin-top-30px">
              <div style={{ marginRight: '38px' }}>
                <MonthDropdown month={inputMonth} setMonth={setInputMonth} />
              </div>
              <div style={{ marginRight: '38px' }}>
                <YearDropdown setYear={setInputYear} year={inputYear} />
              </div>
              <button
                className="font-ta-v2-custom-small-header-bold confirm-btn"
                disabled={isBtnDisabled}
                type="button"
                onClick={checkAge}
              >
                <LocaleFragment message={localeCommon.ageGate.confirmBtn} />
              </button>
            </div>
            <div
              className="d-flex flex-column align-center font-ta-v2-global-regulatory-text-small text-color-white text-center margin-top-30px"
              style={{ paddingLeft: '15px', paddingRight: '15px' }}
            >
              <p>
                <LocaleFragment message={localeCommon.ageGate.support} />
              </p>
              <p>
                <LocaleFragment message={localeCommon.ageGate.copyright} />
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
