import cn from 'classnames';

import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import { LocaleFragment } from '@lib/tools/locale/views/LocaleFragment';

interface Props {
  maxChoicesCount: number;
  selectedChoicesCount: number;
  className?: string;
  isLight?: boolean;
}

export const AnswerSectionHeader = ({ maxChoicesCount, selectedChoicesCount, className, isLight }: Props) => {
  return (
    <div
      className={cn('ta-v2-answer-section-header', className, {
        isLight,
      })}
    >
      <span className="font-ta-v2-global-caption-medium">
        {maxChoicesCount > 1 ? (
          <LocaleFragment message={localeV2.quiz.answerSectionHeaderMultiple} />
        ) : (
          <LocaleFragment message={localeV2.quiz.answerSectionHeaderSingle} />
        )}
      </span>
      {maxChoicesCount > 1 && (
        <span className="font-ta-v2-global-caption-medium">{`${selectedChoicesCount}/${maxChoicesCount}`}</span>
      )}
    </div>
  );
};
