import { FC } from 'react';

import { localeCommon } from '@lib/tools/locale/source/pmi/common';
import { LocaleFragment } from '@lib/tools/locale/views/LocaleFragment';

interface DisclaimerPropsType {
  privacyLink: string;
  text?: JSX.Element | null;
}

export const Disclaimer: FC<DisclaimerPropsType> = ({ privacyLink, text = null }) => (
  <div className="taste-advisor-disclaimer text-center">
    <div className="taste-advisor-p3 mx-auto d-block">
      {text || (
        <div>
          <LocaleFragment message={localeCommon.startPage.privacyText} />
          &nbsp;
          <a
            className="text-decoration-underline"
            href={privacyLink || 'https://www.pmiprivacy.com/consumer/'}
            rel="noopener noreferrer"
            target="_blank"
          >
            <LocaleFragment message={localeCommon.startPage.privacyLink} />
          </a>
        </div>
      )}
    </div>
  </div>
);
