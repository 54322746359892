import { Dispatch, FC, SetStateAction } from 'react';

import centerIcon from '@app/pmi/src/assets/media/crafted/iconCenter.png';
import { ReactComponent as LeftArrow } from '@app/pmi/src/assets/media/crafted/leftArrow.svg';
import { ReactComponent as RightArrow } from '@app/pmi/src/assets/media/crafted/rightArrow.svg';

import { TQuizData } from '@lib/core/quizzes/types';

import { GearComponent } from '@components/pmi/src/botanicals/organisms/Gears/Gear';

interface IGearsProps {
  quizData: TQuizData;
  setSelectedAnswers: Dispatch<
    SetStateAction<{
      1: string;
      2: string;
      3: string;
    }>
  >;
  isResetResult: boolean;
  isShowArrows: boolean;
  selectedAnswers: { 1: string; 2: string; 3: string };
}

export const Gears: FC<IGearsProps> = ({
  isShowArrows,
  selectedAnswers,
  isResetResult,
  setSelectedAnswers,
  quizData,
}) => {
  const onClickQuizAnswer = (quizAnswerId: string, gearIndex) => {
    setSelectedAnswers(rv => {
      return { ...rv, [gearIndex]: quizAnswerId };
    });
  };

  return (
    <div className="gears-block">
      {isShowArrows ? (
        <div className="gears-block-arrows">
          <LeftArrow className="gears-block-arrows-style" />
          <RightArrow className="gears-block-arrows-style" />
        </div>
      ) : null}
      <div className="gears-block-icon" style={{ backgroundImage: `url(${centerIcon})` }} />

      {Object.keys(quizData).map((questionId, index) => (
        <GearComponent
          key={index}
          elementId={`gear-answers-${index + 1}`}
          gearOrder={index + 1}
          isResetResult={isResetResult}
          quizAnswers={quizData[questionId].answers}
          selectedAnswerId={selectedAnswers[index + 1]}
          onClickQuizAnswer={onClickQuizAnswer}
        />
      ))}
    </div>
  );
};
