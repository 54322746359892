import cn from 'classnames';
import { FC, useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { TLanguage } from '@lib/core/retailers/types';
import { DropdownSelect } from '@lib/tools/devtools/components/inputs/Select';
import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import { LocaleFragment } from '@lib/tools/locale/views/LocaleFragment';
import { DUTY_FREE_RETAILER_SLUGS, HEETS, TEREA, VEEV } from '@lib/tools/shared/pmi/retailers/consts';
import { useHeight } from '@lib/tools/views/hooks';

import { Button } from '@components/pmi/src/atoms/CTA/Button';
import { HealthWarningFooterToIsrael } from '@components/pmi/src/common/HealthWarningFooterToIsrael';
import { HealthWarningFooter } from '@components/pmi/src/organisms/HealthWarning/HealthWarning';
import { LanguageSwitcher } from '@components/pmi/src/organisms/LanguageSwitcher/LanguageSwitcher';
import { CustomInfoPage } from '@components/pmi/src/templates/CustomInfoPage/CustomInfoPage';
import { isMediaVideo } from '@components/pmi/src/utils';

interface Props {
  productCategory: string;
  isServiceInstanceDutyFree: boolean;
  isServiceInstanceShortRecommender: boolean;
  productLogo: string;
  backgroundVideo: string;
  supportedLanguages: TLanguage[];
  isEnableDisclaimerAddon: boolean;
  isHideIqosAddon: boolean;
  isCustomInfoPageAddon: boolean;
  selectedLanguageCode: string;
  onLanguageSelect: (code: string) => void;
  onDutyFreeRetailerSelect: (retailerSlug: string) => void;
  handleStartTest: () => void;
  isLight?: boolean;
  isLoadingQuizzes: boolean;
  isHealthWarningFooterToIsraelAddon: boolean;
}

export const LandingPage: FC<Props> = ({
  productCategory,
  isServiceInstanceDutyFree,
  isServiceInstanceShortRecommender,
  productLogo,
  backgroundVideo,
  supportedLanguages,
  isEnableDisclaimerAddon,
  selectedLanguageCode,
  onLanguageSelect,
  onDutyFreeRetailerSelect,
  handleStartTest,
  isHideIqosAddon,
  isLoadingQuizzes,
  isLight,
  isCustomInfoPageAddon,
  isHealthWarningFooterToIsraelAddon,
}) => {
  const [footerRef, paddingForFooter] = useHeight<HTMLDivElement>();
  const isMobileResolution = useMediaQuery({ maxWidth: 767 });
  const isVideoFormat = isMediaVideo(backgroundVideo);

  const getDisclaimerText = () => {
    let disclaimerText;
    if (productCategory === HEETS) {
      disclaimerText = <LocaleFragment message={localeV2.landing.disclaimerTextHeets} />;
    } else if (productCategory === TEREA) {
      disclaimerText = <LocaleFragment message={localeV2.landing.disclaimerTextFirstTerea} />;
    } else {
      disclaimerText = <LocaleFragment message={localeV2.landing.disclaimerTextVeev} />;
    }

    return disclaimerText;
  };

  const [openCustomInfoPage, setOpenCustomInfoPage] = useState<boolean>(() => {
    // getting stored value
    const saved = localStorage.getItem('openCustomInfoPage');
    const savedValue = JSON.parse(saved);
    return savedValue || false;
  });

  const optionsDutyFreeOriginCountries = [
    <option key="" value="">
      choose origin country
    </option>,
  ];
  DUTY_FREE_RETAILER_SLUGS.forEach(retailer => {
    optionsDutyFreeOriginCountries.push(
      <option key={retailer} value={retailer}>
        {retailer}
      </option>,
    );
  });

  const [footerHeightGrows, setFooterHeightGrows] = useState(false);
  const [y, setY] = useState(window.scrollY);

  const handleNavigation = useCallback(
    e => {
      const window = e.currentTarget;
      if (y <= window.scrollY) {
        setFooterHeightGrows(true);
      } else {
        setFooterHeightGrows(false);
      }
      setY(window.scrollY);
    },
    [y],
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener('scroll', handleNavigation);

    return () => {
      window.removeEventListener('scroll', handleNavigation);
    };
  }, [handleNavigation]);

  useEffect(() => {
    // storing custom info page open state
    localStorage.setItem('openCustomInfoPage', JSON.stringify(openCustomInfoPage));
  }, [openCustomInfoPage]);

  return (
    <>
      <div
        key={productCategory}
        className={cn(
          'ta-v2-landing-page',
          `${isHealthWarningFooterToIsraelAddon ? 'minh-101vh padding-bottom-11vh' : 'minh-100vh'}`,
          {
            'heets-background': productCategory === HEETS,
            'terea-background': productCategory === TEREA,
            'veev-background': productCategory === VEEV,
          },
        )}
        style={{
          background: `${isVideoFormat ? '' : `url(${backgroundVideo}) center/cover no-repeat`}`,
          paddingBottom: paddingForFooter,
        }}
      >
        {isVideoFormat ? (
          <video autoPlay muted playsInline className="ta-v2-landing-page-cover-video" loop={productCategory !== HEETS}>
            <source src={backgroundVideo} type="video/mp4" />
          </video>
        ) : null}
        <div
          className={cn('ta-v2-landing-content-wrapper', {
            isLight,
          })}
        >
          <LanguageSwitcher
            isLight={productCategory === VEEV}
            selectedLanguageCode={selectedLanguageCode}
            supportedLanguages={supportedLanguages}
            onLanguageSelect={onLanguageSelect}
          />

          {isHideIqosAddon ? null : <img alt="logo" className="ta-v2-landing-page-logo" src={productLogo} />}

          <div className={`${isMobileResolution ? 'font-ta-v2-title-3' : 'font-ta-v2-title-2'}`}>
            {productCategory === VEEV ? (
              <LocaleFragment message={localeV2.landing.veevTitle} />
            ) : (
              <LocaleFragment message={localeV2.landing.title} />
            )}
          </div>

          {!isServiceInstanceShortRecommender && (
            <div className="font-ta-v2-super-paragraph-large">
              {productCategory === VEEV ? (
                <LocaleFragment message={localeV2.landing.veevSubTitle} />
              ) : (
                <LocaleFragment message={localeV2.landing.subTitle} />
              )}
            </div>
          )}

          {isServiceInstanceDutyFree ? (
            <div className="font-ta-v2-global-caption-medium" style={{ margin: 8 }}>
              <DropdownSelect
                options={optionsDutyFreeOriginCountries}
                title="Duty Free "
                onChange={e => {
                  // ? Dispatch language of the DF retailer as well.
                  onDutyFreeRetailerSelect(e.target.value);
                }}
              />
            </div>
          ) : null}

          <Button
            btnText={<LocaleFragment message={localeV2.landing.cta} />}
            className="ta-v2-landing-cta"
            isDark={false}
            isDisabled={isLoadingQuizzes}
            onBtnClick={handleStartTest}
          />
        </div>

        {isEnableDisclaimerAddon ? (
          <div
            className={cn('ta-v2-landing-disclaimer', {
              'font-ta-v2-global-regulatory-text-medium': !isMobileResolution,
              'font-ta-v2-global-regulatory-text-small': isMobileResolution,
              'special-bootom-padding': footerHeightGrows && isHealthWarningFooterToIsraelAddon,
            })}
          >
            <div className="ta-v2-landing-disclaimer-content">
              {getDisclaimerText()}{' '}
              <a
                className="ta-v2-landing-disclaimer-link"
                href="https://www.pmiprivacy.com/consumer/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <LocaleFragment message={localeV2.landing.disclaimerLinkPrivacy} />
              </a>
              {productCategory === TEREA && (
                <div>
                  {<LocaleFragment message={localeV2.landing.disclaimerTextSecondTerea} />}{' '}
                  <a
                    className="ta-v2-landing-disclaimer-link"
                    href="https://www.iqos.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <LocaleFragment message={localeV2.landing.disclaimerLinkIqos} />
                  </a>
                </div>
              )}
            </div>
            {isCustomInfoPageAddon ? (
              <div className="ta-v2-landing-disclaimer-content margin-top-15px">
                <button className="ta-v2-landing-disclaimer-link" onClick={() => setOpenCustomInfoPage(prev => !prev)}>
                  <LocaleFragment message={localeV2.landing.customInfoPageLink} />
                </button>
              </div>
            ) : null}
          </div>
        ) : null}
        <div ref={footerRef} className="ta-v2-landing-footer">
          {!isHealthWarningFooterToIsraelAddon && <HealthWarningFooter />}
        </div>
        {isHealthWarningFooterToIsraelAddon && <HealthWarningFooterToIsrael />}
      </div>
      <div
        className={cn('ta-v2-custom-info-page-cover', {
          'ta-v2-custom-info-page-cover-open': openCustomInfoPage,
        })}
      >
        <CustomInfoPage
          selectedLanguageCode={selectedLanguageCode}
          supportedLanguages={supportedLanguages}
          onClose={() => setOpenCustomInfoPage(prev => !prev)}
          onLanguageSelect={onLanguageSelect}
        />
      </div>
    </>
  );
};
