import classNames from 'classnames';

import { useApp } from '@lib/core/service/hooks';
import { localeCommon } from '@lib/tools/locale/source/pmi/common';
import { LocaleFragment } from '@lib/tools/locale/views/LocaleFragment';

import { isOpenIntegration } from '@components/pmi/src/utils';

export const InvalidVerificationLinkGate = (): JSX.Element => {
  const { isLayoutRightToLeft } = useApp();
  return (
    <div className="tasteAdvisor-gate">
      <div className="popup d-flex position-absolute w-100vw h-100vh" style={{ left: '0', top: '0' }}>
        <div className="d-flex mx-auto" style={{ maxWidth: '600px' }}>
          <div
            className={classNames(
              'd-flex flex-column text-center text-white text-uppercase font-ta-v2-global-caption-small',
              {
                'letter-spacing-zero': isLayoutRightToLeft,
              },
            )}
          >
            <p>
              <LocaleFragment message={localeCommon.remoteAccessBlock.courtesy} />
            </p>
            <p>
              {isOpenIntegration() ? (
                <LocaleFragment message={localeCommon.remoteAccessBlock.courtesy} />
              ) : (
                <LocaleFragment message={localeCommon.remoteAccessBlock.linkExpired} />
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
