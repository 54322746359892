import cn from 'classnames';
import { Dispatch, SetStateAction } from 'react';

import { ReactComponent as TickLight } from '@app/pmi/src/assets/media/V2/v2_tick_circle_light.svg';
import { ReactComponent as TickSlate } from '@app/pmi/src/assets/media/V2/v2_tick_circle_slate.svg';

import { useApp } from '@lib/core/service/hooks';
import { handleClick } from '@lib/tools/shared/pmi/quizzes/selectAnswer';

interface Props {
  answerText: string;
  className?: string;
  answerId: string;
  maxAnswersChoices: number;
  setAnswersArray: Dispatch<SetStateAction<string[]>>;
  answersArray: string[];
  handleConfirmAnswer: (answerId: string, callback: () => void) => void;
  currentPrimaryColor?: string;
  isLight?: boolean;
}

export const AnswerItem = ({
  answerText,
  className,
  answerId,
  setAnswersArray,
  answersArray = [],
  maxAnswersChoices,
  handleConfirmAnswer,
  currentPrimaryColor,
  isLight = false,
}: Props) => {
  const isSelected = answersArray.includes(answerId);
  const { isLayoutRightToLeft } = useApp();

  return (
    <button
      style={{ background: isSelected && currentPrimaryColor }}
      className={cn('ta-v2-qds8001-answerItem-btn', className, {
        'rtl-orientation': isLayoutRightToLeft,
      })}
      onClick={() => handleClick({ answerId, answersArray, handleConfirmAnswer, maxAnswersChoices, setAnswersArray })}
    >
      <span
        className={cn('font-ta-v2-super-paragraph-small ta-v2-qds8001-answerItem-btn-text', {
          'font-weight-bold': isSelected,
          'light-color': isLight && isSelected,
        })}
      >
        {answerText}
      </span>
      <div className={cn('ta-v2-qds8001-answerItem-btn-icon', { isSelected })}>
        {isLight ? <TickLight /> : <TickSlate />}
      </div>
    </button>
  );
};
