import { FC, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { TQuizAnswerData } from '@lib/core/quizzes/types';
import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import { LocaleFragment } from '@lib/tools/locale/views/LocaleFragment';
import { useHeight } from '@lib/tools/views/hooks';

import { Button } from '@components/pmi/src/atoms/CTA/Button';
import { ButtonScrollDown } from '@components/pmi/src/atoms/CTA/ButtonScrollDown';
import { HealthWarningFooterToIsrael } from '@components/pmi/src/common/HealthWarningFooterToIsrael';
import { useCurrentQuizBackground } from '@components/pmi/src/hooks/useCurrentQuizBackground';
import { Header } from '@components/pmi/src/organisms/Header/Header';
import { HealthWarningFooter } from '@components/pmi/src/organisms/HealthWarning/HealthWarning';
import { AnswerSectionHeader } from '@components/pmi/src/organisms/Quiz/AnswerSectionHeader/AnswerSectionHeader';
import { AnswerItem } from '@components/pmi/src/organisms/Quiz/QDS8012/AnswerItem/AnswerItem';
import { QuizFooter } from '@components/pmi/src/organisms/Quiz/QuizFooter/QuizFooter';

interface Props {
  isLight?: boolean;
  answers: TQuizAnswerData[];
  questionTitle: string;
  questionCaption: string;
  progressPercent: number;
  handleConfirmAnswer: (answerId: string | string[], callback: () => void) => void;
  goBack: () => void;
  questionImage: string;
  choicesQuantity: {
    maxChoices: number;
    minChoices: number;
  };
  isShowBackButton: boolean;
  isHealthWarningQuizAddon: boolean;
  isLoading: boolean;
  isHeetsBackgroundEnabled: boolean;
  currentPrimaryColor: string;
  isShowRestrictedBackground: boolean;
  isHealthWarningFooterToIsraelAddon?: boolean;
}

export const QuizPageQDS8012: FC<Props> = ({
  questionTitle,
  questionCaption,
  answers,
  progressPercent,
  handleConfirmAnswer,
  goBack,
  questionImage,
  choicesQuantity,
  isShowBackButton,
  isLoading,
  isHeetsBackgroundEnabled,
  isHealthWarningQuizAddon,
  isLight = false,
  currentPrimaryColor,
  isShowRestrictedBackground,
  isHealthWarningFooterToIsraelAddon,
}) => {
  const bottomRef = useRef(null);
  const [answersArray, setAnswersArray] = useState<string[]>([]);
  const handleClick = () => {
    handleConfirmAnswer(answersArray, () => {
      setAnswersArray([]);
    });
  };
  const [footerRef, paddingForFooter] = useHeight<HTMLDivElement>();
  const minChoicesQuantity = choicesQuantity?.minChoices;
  const maxAnswersChoices = choicesQuantity?.maxChoices;
  const isMobileResolution = useMediaQuery({ maxWidth: 767 });
  const isNextButtonEnabled = answersArray.length >= minChoicesQuantity && maxAnswersChoices >= 2;
  const currentBackground = useCurrentQuizBackground(questionImage, isShowRestrictedBackground);

  return (
    <div
      ref={bottomRef}
      className={`ta-v2-qds8012-page ${isHealthWarningFooterToIsraelAddon ? 'padding-bottom-30vh minh-101vh' : 'minh-100vh'}`}
      style={{
        background: currentBackground,
        paddingBottom: paddingForFooter,
      }}
    >
      <Header
        caption={questionCaption}
        currentPrimaryColor={currentPrimaryColor}
        goBack={goBack}
        isHeetsBackgroundEnabled={isHeetsBackgroundEnabled}
        isLoading={isLoading}
        isShowBackButton={isShowBackButton}
        progressPercent={progressPercent}
        title={questionTitle}
      />
      <div className="ta-v2-qds8012-page-content-part">
        <div className="ta-v2-qds8012-page-answer-section">
          {answers?.length && (
            <AnswerSectionHeader
              isLight={isLight}
              maxChoicesCount={maxAnswersChoices}
              selectedChoicesCount={answersArray?.length || 0}
            />
          )}
          <div className="ta-v2-qds8012-page-answer-item-container">
            {answers?.map(answer => {
              const answerId = Object.keys(answer)[0];
              return (
                <AnswerItem
                  key={answerId}
                  answerId={answerId}
                  answerImage={answer[answerId].image}
                  answerText={answer[answerId].text}
                  answersArray={answersArray}
                  currentPrimaryColor={currentPrimaryColor}
                  handleConfirmAnswer={handleConfirmAnswer}
                  isLight={isLight}
                  maxAnswersChoices={maxAnswersChoices}
                  setAnswersArray={setAnswersArray}
                />
              );
            })}
          </div>
        </div>
        {!isMobileResolution && maxAnswersChoices > 1 ? (
          <Button
            btnText={<LocaleFragment message={localeV2.common.nextQuestion} />}
            className="ta-v2-cta-btn-margin"
            isDark={isNextButtonEnabled}
            isDisabled={!isNextButtonEnabled}
            onBtnClick={handleClick}
          />
        ) : null}
      </div>
      <div ref={footerRef} className="ta-v2-quiz-footer-wrapper">
        <ButtonScrollDown
          showScrollArrow
          bottomRef={bottomRef}
          isHealthWarningFooterToIsraelAddon={isHealthWarningFooterToIsraelAddon}
        />
        {isMobileResolution && maxAnswersChoices > 1 ? (
          <QuizFooter handleClick={handleClick} isNextButtonEnabled={isNextButtonEnabled} />
        ) : null}
        {isHealthWarningQuizAddon && <HealthWarningFooter />}
      </div>
      {isHealthWarningFooterToIsraelAddon && <HealthWarningFooterToIsrael />}
    </div>
  );
};
