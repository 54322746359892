import cn from 'classnames';
import { FC, MutableRefObject, useEffect, useState } from 'react';

import { localeCommon } from '@lib/tools/locale/source/pmi/common';
import { LocaleFragment } from '@lib/tools/locale/views/LocaleFragment';

interface Props {
  reference?: MutableRefObject<any>;
  className?: string;
}

export const HealthWarningFooterToIsrael: FC<Props> = ({ className = '', reference }) => {
  const [footerHeightGrows, setFooterHeightGrows] = useState(false);

  const healthWarningFirstWord = (
    <LocaleFragment message={localeCommon.healthWarning.iqos3HealthWarningFooterToIsraelWarning} />
  );
  const healthWarningMessage = (
    <LocaleFragment message={localeCommon.healthWarning.iqos3HealthWarningFooterToIsraelWarningText} />
  );

  const growHandler = () => setFooterHeightGrows(prev => !prev);

  useEffect(() => {
    const scrollHandler = () => {
      // round to greater integer value to fix bug in Chrome where scrollY is not always an integer
      if (Math.ceil(window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        setFooterHeightGrows(true);
      } else {
        setFooterHeightGrows(false);
      }
    };

    document.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  return (
    <div
      ref={reference}
      role="button"
      tabIndex={0}
      className={cn('health-warning-footer-to-israel', className, {
        'big-footer-height': footerHeightGrows,
        'small-footer-height': !footerHeightGrows,
      })}
      onClick={growHandler}
      onKeyDown={growHandler}
    >
      <div ref={reference} className="block-to-israel">
        <p className="haim-h1 israel-info-text">
          <span className="text-uppercase text-decoration-underline haim-h1-warning">{healthWarningFirstWord}</span>
          &nbsp;
          {healthWarningMessage}
        </p>
      </div>
    </div>
  );
};
