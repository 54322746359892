import cn from 'classnames';
import { useEffect, useState } from 'react';

import slateBackgroundImage from '@app/pmi/src/assets/media/V2/v2_slate_background.png';

import { useApp } from '@lib/core/service/hooks';
import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import { ILocaleText, LocaleFragment } from '@lib/tools/locale/views/LocaleFragment';

import { ButtonNavigator } from '@components/pmi/src/atoms/CTA/ButtonNavigator';
import { ProgressBar } from '@components/pmi/src/atoms/ProgressBar/ProgressBar';
import { ProductReferenceCard } from '@components/pmi/src/organisms/Quiz/ProductReferenceCard/ProductReferenceCard';

interface Props {
  caption: ILocaleText;
  isLoading: boolean;
  isHeetsBackgroundEnabled?: boolean;
  title: ILocaleText;
  className?: string;
  isVeev?: boolean;
  questionHeaderProductCardDetails?: {
    productCardCaption: string;
    productCardEssenceDescription: string;
    productCardImage: string;
    productCardName: string;
    productCardLinkText: ILocaleText;
    productCardPrimaryColor: string;
    productCardShowEssenceLink: boolean;
    goChange: () => void;
    isPearlProduct: boolean;
    isQDS8003?: boolean;
    isTobaccoExperienceHiddenAddon: boolean;
  };
  currentPrimaryColor: string;
  progressPercent?: number;
  isHideProgressBar?: boolean;
  goBack?: () => void;
  isShowBackButton: boolean;
}

export const Header = ({
  className,
  caption,
  isLoading,
  title,
  questionHeaderProductCardDetails,
  isHeetsBackgroundEnabled = false,
  currentPrimaryColor,
  isVeev = false,
  progressPercent,
  isHideProgressBar = false,
  goBack,
  isShowBackButton,
}: Props) => {
  const [showProductCard, setShowProductCard] = useState(false);
  const { isLayoutRightToLeft } = useApp();

  useEffect(() => {
    if (questionHeaderProductCardDetails && Object.keys(questionHeaderProductCardDetails)) {
      setShowProductCard(true);
    } else {
      setShowProductCard(false);
    }
  }, [questionHeaderProductCardDetails]);
  return (
    <div
      className={cn('ta-v2-header-cover', className, {
        'ta-v2-header-cover-heets': isHeetsBackgroundEnabled,
      })}
      style={{
        backgroundImage: `url(${isHeetsBackgroundEnabled && slateBackgroundImage})`,
      }}
    >
      {isShowBackButton ? (
        <ButtonNavigator
          btnText={<LocaleFragment message={localeV2.common.back} />}
          direction="left"
          isDark={!isHeetsBackgroundEnabled}
          isLayoutRightToLeft={isLayoutRightToLeft}
          className={`${
            isLayoutRightToLeft ? 'ta-v2-header-cover-btn-position-right' : 'ta-v2-header-cover-btn-position-left'
          }`}
          onBtnClick={goBack}
        />
      ) : null}

      {!isHideProgressBar ? (
        <ProgressBar
          className="ta-v2-header-cover-progress-bar-position"
          currentPrimaryColor={currentPrimaryColor}
          isLayoutRightToLeft={isLayoutRightToLeft}
          progressPercentage={progressPercent}
        />
      ) : null}

      {isLoading || (!title && !caption) ? (
        <div className="ta-v2-header-load-box-cover">
          <div className="ta-v2-header-load-box" />
        </div>
      ) : (
        <>
          <div
            className={cn('ta-v2-header-text-cover', {
              'ta-v2-header-text-no-gap': !title || !caption,
            })}
          >
            <span className="font-ta-v2-title-6">
              <LocaleFragment message={title} />
            </span>
            <span className="font-ta-v2-global-body-large">
              <LocaleFragment message={caption} />
            </span>
          </div>
          {showProductCard && (
            <div className="ta-v2-header-product-reference-card-container">
              <ProductReferenceCard
                caption={questionHeaderProductCardDetails?.productCardCaption}
                classname="ta-v2-header-product-reference-card"
                essenceDescription={questionHeaderProductCardDetails?.productCardEssenceDescription}
                goChange={questionHeaderProductCardDetails?.goChange}
                image={questionHeaderProductCardDetails?.productCardImage}
                isPearlProduct={questionHeaderProductCardDetails?.isPearlProduct}
                isQDS8003={questionHeaderProductCardDetails?.isQDS8003}
                isTobaccoExperienceHiddenAddon={questionHeaderProductCardDetails?.isTobaccoExperienceHiddenAddon}
                isVeev={isVeev}
                name={questionHeaderProductCardDetails?.productCardName}
                primaryColor={questionHeaderProductCardDetails?.productCardPrimaryColor}
                productCardLinkText={questionHeaderProductCardDetails?.productCardLinkText}
                showEssenceLink={questionHeaderProductCardDetails?.productCardShowEssenceLink}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
