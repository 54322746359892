import classNames from 'classnames';
import { FC } from 'react';

import { localeCommon } from '@lib/tools/locale/source/pmi/common';
import { LocaleFragment } from '@lib/tools/locale/views/LocaleFragment';

export const Geoblock: FC = () => (
  <div className="tasteAdvisor-gate">
    <div className="d-flex text-center flex-column align-center popup position-absolute w-100vw h-100vh">
      <div
        className={classNames('font-ta-v2 d-flex text-center flex-column text-bold mx-auto justify-content-center', {
          'text-uppercase text_title_geoblock': true,
        })}
      >
        <p>
          <LocaleFragment message={localeCommon.geoBlock.courtesy} />
        </p>

        <p>
          <LocaleFragment message={localeCommon.geoBlock.compliancePartOne} />
        </p>

        <p>
          <LocaleFragment message={localeCommon.geoBlock.compliancePartTwo} />
        </p>

        <p>
          <LocaleFragment message={localeCommon.geoBlock.compliancePartThree} />
        </p>
      </div>
    </div>
  </div>
);
