import cn from 'classnames';
import { FC } from 'react';

import { ReactComponent as CloseIcon } from '@app/pmi/src/assets/media/V2/closePopUp.svg';

import { ILocaleText, LocaleFragment } from '@lib/tools/locale/views/LocaleFragment';

interface PopUpCardProps {
  onClose: () => void;
  isOpen: boolean;
  essenceTitle: ILocaleText;
  essenceDescription: string;
}

export const PopUpCard: FC<PopUpCardProps> = ({ essenceTitle, essenceDescription, isOpen, onClose }) => {
  return (
    <div
      className={cn('ta-v2-popup-card', {
        'ta-v2-popup-card-open': isOpen,
      })}
    >
      <div className="ta-v2-popup-card-header font-ta-v2-super-paragraph-small font-weight-bold">
        <LocaleFragment message={essenceTitle} />

        <button className="ta-v2-popup-card-btn" onClick={onClose}>
          <CloseIcon />
        </button>
      </div>
      <div className="ta-v2-popup-card-line" />
      <div className="font-ta-v2-global-body-large">{essenceDescription}</div>
    </div>
  );
};
