import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { IntlShape, injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { TProductCategory } from '@lib/core/products/types';
import { useRemoteAccess } from '@lib/core/retailers/hooks/remoteAccess';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { actionGenerateRemoteAccessKey } from '@lib/core/retailers/slices/remoteAccess';
import { IRetailerLanguage } from '@lib/core/retailers/types/retailer';
import { useApp } from '@lib/core/service/hooks';
import { RouteUtils } from '@lib/tools/routes';
import { PAGES } from '@lib/tools/views/urls';
import { ErrorPageNotFound } from '@lib/tools/views/web/components/ErrorPageNotFound';

import { AccessKeyGenerator } from '@components/pmi/src/admin/remoteAccess/RemoteAccessPage';
import { getExpirationLabel, getExpirationPeriod } from '@components/pmi/src/utils';

interface RemoteAccessKeygenContainerProps {
  intl: IntlShape;
}

const RemoteAccessKeygenContainer: FC<RemoteAccessKeygenContainerProps> = ({ intl }) => {
  const dispatch = useDispatch();

  const { isRetailerLoading, retailerId, retailerName, retailerSlug, retailerLanguages, retailerError } = useRetailer();
  const { retailerLocationProductCategories } = useRetailerLocation();
  const { remoteAccessKey, remoteAccessExpiry, remoteAccessRetries, remoteAccessCustomId } = useRemoteAccess();
  const { locale, isLayoutRightToLeft } = useApp();

  const [selectedLanguage, setSelectedLanguage] = useState<IRetailerLanguage>();
  const [selectedExpirationPeriod, setSelectedExpirationPeriod] = useState({
    label: getExpirationLabel(1, intl),
    value: 1,
  });
  const [selectedNumberOfUses, setSelectedNumberOfUses] = useState({
    label: '1',
    value: 1,
  });
  const [generatedLink, setGeneratedLink] = useState('');
  const [selectedProductCategory, setSelectedProductCategory] = useState<TProductCategory>();

  const { expiryHours, retries, customId } = RouteUtils.getRemoteAccessParams();
  const expirySelectedValue = getExpirationPeriod(selectedExpirationPeriod.value);

  useEffect(() => {
    if (!selectedProductCategory && retailerLocationProductCategories.length) {
      setSelectedProductCategory(retailerLocationProductCategories[0]);
    }
  }, [retailerLocationProductCategories, selectedProductCategory]);

  useEffect(() => {
    if (locale) {
      setSelectedLanguage(retailerLanguages.find(lang => lang.code === locale));
    } else {
      setSelectedLanguage(retailerLanguages[0]);
    }
  }, [locale, retailerLanguages]);

  useEffect(() => {
    // Construct the Remote GT URL
    if (
      remoteAccessKey &&
      remoteAccessExpiry &&
      remoteAccessRetries &&
      selectedLanguage?.code &&
      selectedProductCategory
    ) {
      const remoteGTUrl = new URL(
        `${window.location.origin}/${selectedLanguage.code}/${retailerSlug}/p/${selectedProductCategory}${PAGES.ta.landingRemote}`,
      );

      remoteGTUrl.searchParams.append(RouteUtils.remoteAccessParams.accessKey, remoteAccessKey);
      remoteGTUrl.searchParams.append(RouteUtils.remoteAccessParams.expiry, remoteAccessExpiry);
      remoteGTUrl.searchParams.append(RouteUtils.remoteAccessParams.retries, remoteAccessRetries.toString());

      if (remoteAccessCustomId.length) {
        remoteGTUrl.searchParams.append(RouteUtils.remoteAccessParams.customId, remoteAccessCustomId.toString());
      }

      setGeneratedLink(remoteGTUrl.toString());
    }
  }, [remoteAccessKey, remoteAccessExpiry, remoteAccessRetries, selectedProductCategory, selectedLanguage]);

  const handleGenerateLink = () => {
    const { type, quantity } = expirySelectedValue;
    if (type === 'hours' || type === 'week' || type === 'month' || type === 'year') {
      dispatch(
        actionGenerateRemoteAccessKey({
          custom_id: customId,
          retailer_id: retailerId,
          retries: retries || selectedNumberOfUses.label,
          timestamp: expiryHours
            ? `${moment().add(expiryHours, 'hours').utc().format('YYYY-MM-DDTHH:mm:ss')}+00:00`
            : `${moment().add(quantity, type).format('YYYY-MM-DDTHH:mm:ss')}+00:00`,
        }),
      );
    }
  };

  if (isRetailerLoading) {
    return null;
  }

  if (retailerError.length) {
    return <ErrorPageNotFound />;
  }

  return (
    <AccessKeyGenerator
      expiryHoursValue={expiryHours}
      generatedLink={generatedLink}
      handleGenerateLink={handleGenerateLink}
      isLayoutRightToLeft={isLayoutRightToLeft}
      productCategories={retailerLocationProductCategories}
      resetGeneratedLink={() => setGeneratedLink('')}
      retailerLanguages={retailerLanguages}
      retailerName={retailerName}
      retriesValue={retries}
      selectedExpirationPeriod={selectedExpirationPeriod}
      selectedLanguage={selectedLanguage}
      selectedNumberOfUses={selectedNumberOfUses}
      selectedProductCategory={selectedProductCategory}
      setSelectedDesignSet={setSelectedProductCategory}
      setSelectedExpirationPeriod={setSelectedExpirationPeriod}
      setSelectedLanguage={setSelectedLanguage}
      setSelectedNumberOfUses={setSelectedNumberOfUses}
    />
  );
};

export const AccessKeygenContainer = injectIntl(RemoteAccessKeygenContainer);
