import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { broadcastDomHeightToHost } from '@app/pmi/src/comms/utils';

import { useQuizzes } from '@lib/core/quizzes/hooks';
import { useRemoteAccess } from '@lib/core/retailers/hooks/remoteAccess';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useApp } from '@lib/core/service/hooks';
import { useServiceInstance } from '@lib/core/service/hooks/useServiceInstance';
import { setServiceLocale } from '@lib/core/service/slices';
import { actionUpdateServiceInstance } from '@lib/core/service/slices/serviceInstanceSlice';
import { prependBasename } from '@lib/core/service/utils';
import { HEETS, TEREA, VEEV } from '@lib/tools/shared/pmi/retailers/consts';
import { useAddons, useRetailerDesignSet } from '@lib/tools/views/hooks';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';
import { PAGES } from '@lib/tools/views/urls';

import { LandingPage as CraftedLandingPage } from '@components/pmi/src/botanicals/templates/LandingPage/LandingPage';
import { LandingPage } from '@components/pmi/src/templates/LandingPage/LandingPage';

import imgBackground from 'assets/media/crafted/background_cover.png';
import imgLogo from 'assets/media/crafted/botanicals_landing_logo.svg';
import TAV2LandingPageHeetsBgVideo from 'assets/media/V2/heets_v2_landing_restricted_video_original.mp4';
import TAV2LandingPageTereaBgVideo from 'assets/media/V2/terea_v2_landing_restricted_video_original.mp4';

export const LandingContainer = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const { locale } = useApp();
  const {
    serviceInstanceType: { isServiceInstanceDutyFree, isServiceInstanceShortRecommender },
  } = useServiceInstance();

  const { retailerLanguages, retailerTags } = useRetailer();

  const { productCategory } = useApp();

  const { isQuizzesFetching } = useQuizzes();

  const { isDesignSetPmiCrafted, isDesignSetPmiV2 } = useRetailerDesignSet();

  const { isHideIqosAddon, isCustomInfoPageAddon, isHealthWarningFooterToIsraelAddon } = useAddons();

  const { remoteAccessKey } = useRemoteAccess();

  useEffect(() => {
    broadcastDomHeightToHost();
  }, [productCategory]);

  const handleStartTasteLocalTest = () => {
    let currentQuizPath = '';
    if (remoteAccessKey) {
      currentQuizPath = `${PAGES.ta.quiz.remote}`;
    } else if (isServiceInstanceShortRecommender) {
      currentQuizPath = `${PAGES.ta.quiz.shortRecommender}`;
    } else {
      currentQuizPath = `${PAGES.ta.quiz.local}`;
    }

    navigate(prependBasename(currentQuizPath));
  };

  const onLanguageSelect = code => {
    dispatch(setServiceLocale(code));
  };

  const onDutyFreeRetailerSelect = (retailerSlug: string) => {
    dispatch(
      actionUpdateServiceInstance({
        serviceInstanceRetailerSlug: retailerSlug,
      }),
    );
  };

  if (isDesignSetPmiV2) {
    let currentBgVideo = '';
    let productLogo = '';
    if (productCategory === TEREA) {
      const productLogoTerea = retailerTags.image_cover_terea
        ? retailerTags.image_cover_terea
        : 'https://media-staging.taste-advisor-vh.com/media/logos/v2_landing_logo_terea.png';
      const logoShortRecommender = retailerTags.image_cover_terea_short_recommender
        ? retailerTags.image_cover_terea_short_recommender
        : 'https://media-staging.taste-advisor-vh.com/media/logos/v2_landing_logo_terea.png';
      productLogo = isServiceInstanceShortRecommender ? logoShortRecommender : productLogoTerea;
      currentBgVideo = retailerTags.video_cover_terea ? retailerTags.video_cover_terea : TAV2LandingPageTereaBgVideo;
    }

    if (productCategory === HEETS) {
      productLogo = retailerTags.image_cover_heets
        ? retailerTags.image_cover_heets
        : 'https://media.taste-advisor-vh.com/media/logos/v2_landing_logo_heets.png';
      currentBgVideo = retailerTags.video_cover_heets ? retailerTags.video_cover_heets : TAV2LandingPageHeetsBgVideo;
    }

    if (productCategory === VEEV) {
      productLogo = retailerTags.image_cover_veev
        ? retailerTags.image_cover_veev
        : 'https://media-staging.taste-advisor-vh.com/media/logos/v2_landing_veevone.png';
      currentBgVideo = retailerTags.video_cover_veev ? retailerTags.video_cover_veev : TAV2LandingPageHeetsBgVideo;
    }

    return (
      <LandingPage
        isEnableDisclaimerAddon
        backgroundVideo={currentBgVideo}
        handleStartTest={handleStartTasteLocalTest}
        isCustomInfoPageAddon={isCustomInfoPageAddon}
        isHealthWarningFooterToIsraelAddon={isHealthWarningFooterToIsraelAddon}
        isHideIqosAddon={isHideIqosAddon}
        isLight={productCategory === VEEV}
        isLoadingQuizzes={isQuizzesFetching}
        isServiceInstanceDutyFree={isServiceInstanceDutyFree}
        isServiceInstanceShortRecommender={isServiceInstanceShortRecommender}
        productCategory={productCategory}
        productLogo={productLogo}
        selectedLanguageCode={locale}
        supportedLanguages={retailerLanguages}
        onDutyFreeRetailerSelect={onDutyFreeRetailerSelect}
        onLanguageSelect={onLanguageSelect}
      />
    );
  }

  if (isDesignSetPmiCrafted) {
    return (
      <CraftedLandingPage
        backgroundImage={imgBackground}
        backgroundVideo="https://media-staging.taste-advisor-vh.com/media/video_covers/terea_landing_crafted.mp4"
        productLogo={imgLogo}
        onClick={() => navigate(prependBasename(PAGES.ta.quiz.crafted))}
      />
    );
  }

  return <div />;
};
