import { FC, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { PAGES } from '@lib/tools/views/urls';
import { ErrorPageNotFound } from '@lib/tools/views/web/components/ErrorPageNotFound';
import { ErrorContainer } from '@lib/tools/views/web/containers/Error';

import { AccessBlockContainer } from 'containers/AccessBlockContainer';
import { AccessKeygenContainer } from 'containers/AccessKeygenContainer';
import { BestMatchContainer } from 'containers/BestMatchContainer';
import { BundlesContainer } from 'containers/BundlesContainer';
import { QuizCraftedContainerWithLauncher } from 'containers/CraftedContainer';
import { LandingContainer } from 'containers/LandingContainer';
import { OpenIntegrationContainer } from 'containers/OpenIntegrationContainer';
import { ProductCategorySelectorContainer } from 'containers/ProductCategorySelectorContainer';
import { QuizContainerWithLauncher } from 'containers/QuizContainer';
import { QuizResultContainer } from 'containers/ResultContainer';
import {
  GeoBlockAccess,
  HealthWarningPrePageAccess,
  LegalAgeAccess,
  PatchRemoteGuidedTrialAccess,
  RemoteAccess,
} from 'routes/AccessPmi';

export const RouterPmi: FC = () => {
  const basenameRetailer = '/:lang/:retailer/loc/:location/p/:product';
  const basenameRetailerProductSelect = '/:lang/:retailer/loc/:location';

  const retailerUrl = url => basenameRetailer + url;
  const retailerProductSelectUrl = url => basenameRetailerProductSelect + url;

  const RouteGroup = (c, ...paths) => {
    return paths.map(p => <Route key={p} Component={c} path={p} />);
  };

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route Component={GeoBlockAccess}>
          <Route Component={PatchRemoteGuidedTrialAccess}>
            <Route key="productCategorySelector" path={`${basenameRetailerProductSelect}`}>
              <Route
                key="urlAuthLinkGenerator"
                Component={AccessKeygenContainer}
                path={retailerProductSelectUrl(PAGES.ta.linkGenerator)}
              />
              <Route key="urlCatchAllProductCategory" Component={ProductCategorySelectorContainer} path="*" />
            </Route>
            <Route Component={RemoteAccess}>
              <Route Component={LegalAgeAccess}>
                <Route key="retailer" path={basenameRetailer}>
                  <Route Component={HealthWarningPrePageAccess}>
                    {RouteGroup(
                      LandingContainer,
                      retailerUrl(PAGES.ta.landing),
                      retailerUrl(PAGES.ta.landingRemote),
                      retailerUrl(PAGES.ta.dutyFree),
                      retailerUrl(PAGES.ta.shortRecommender),
                    )}
                  </Route>

                  {RouteGroup(QuizCraftedContainerWithLauncher, retailerUrl(PAGES.ta.quiz.crafted))}
                  {RouteGroup(QuizContainerWithLauncher, retailerUrl(`/quiz/:quizType/`))}

                  <Route
                    key="urlBestMatchPage"
                    Component={BestMatchContainer}
                    path={retailerUrl(PAGES.ta.products.bestMatch)}
                  />

                  <Route
                    key="urlBundlesPage"
                    Component={BundlesContainer}
                    path={retailerUrl(PAGES.ta.products.bundles)}
                  />

                  <Route key="urlResultPage" Component={QuizResultContainer} path={retailerUrl(PAGES.ta.result)} />

                  <Route
                    key="urlOpenIntegration"
                    Component={OpenIntegrationContainer}
                    path={retailerUrl(PAGES.ta.openIntegration)}
                  />

                  <Route key="urlError401" Component={AccessBlockContainer} path={retailerUrl(PAGES.error.forbidden)} />
                  <Route key="urlError404" Component={ErrorPageNotFound} path={retailerUrl(PAGES.error.notFound)} />
                  <Route key="urlError500" Component={ErrorContainer} path={retailerUrl(PAGES.error.badRequest)} />
                  <Route key="urlCatchAllError404" Component={ErrorPageNotFound} path="*" />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};
