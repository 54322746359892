import cn from 'classnames';
import { FC } from 'react';

import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import { LocaleFragment } from '@lib/tools/locale/views/LocaleFragment';

type Props = {
  image: string;
  description: string;
  isHorizontalOrientation?: boolean;
  isDimensionSmall?: boolean;
  className?: string;
  isPearlType: boolean;
};

export const AromaNote: FC<Props> = ({
  image,
  description,
  isHorizontalOrientation = false,
  isDimensionSmall = false,
  className,
  isPearlType,
}) => {
  return (
    <div
      className={cn('ta-v2-aroma-note', className, {
        isDimensionSmall,
        isHorizontalOrientation,
      })}
    >
      <div className="ta-v2-aroma-note-img">
        <img alt="" src={image || ''} />
      </div>
      <div
        className={cn('ta-v2-aroma-note-text', {
          'font-ta-v2-custom-paragraph-bold': !isDimensionSmall,
          'font-ta-v2-custom-small-header-bold ': isDimensionSmall,
        })}
      >
        <span className="font-weight-normal">
          {isPearlType ? (
            <LocaleFragment message={localeV2.product.onAClick} />
          ) : (
            <LocaleFragment message={localeV2.product.aromaNote} />
          )}
        </span>
        <span>{description}</span>
      </div>
    </div>
  );
};
