import { FC } from 'react';

import { localeCommon } from '@lib/tools/locale/source/pmi/common';
import { LocaleFragment } from '@lib/tools/locale/views/LocaleFragment';

export const Error410Page: FC = () => (
  <div className="tasteAdvisor-gate">
    <div className="d-flex text-center flex-column align-center popup position-absolute w-100vw h-100vh">
      <div
        className="font-ta-v2 d-flex text-center flex-column text-bold mx-auto justify-content-center font-ta-v2-custom-large-paragraph-bold"
        style={{ maxWidth: '638px' }}
      >
        <p>
          <LocaleFragment message={localeCommon.error410Page.deactivatedText} />
        </p>
      </div>
    </div>
  </div>
);
