import cn from 'classnames';
import { FC } from 'react';

import { ReactComponent as ArrowLeft } from '@app/pmi/src/assets/media/V2/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from '@app/pmi/src/assets/media/V2/ArrowRight.svg';

interface Props {
  btnText: string | JSX.Element;
  isDark: boolean;
  onBtnClick?: () => void;
  className?: string;
  direction?: 'left' | 'right' | null;
  disabled?: boolean;
  isLayoutRightToLeft?: boolean;
}

export const ButtonNavigator: FC<Props> = ({
  isLayoutRightToLeft = false,
  isDark,
  btnText,
  onBtnClick,
  className,
  direction = null,
  disabled,
}) => {
  return (
    <button
      disabled={disabled}
      type="button"
      className={cn('ta-v2-cta-navigator', className, {
        'ta-v2-cta-navigator-dark': isDark,
        'ta-v2-cta-navigator-left': direction === 'left',
        'ta-v2-cta-navigator-right': direction === 'right',
      })}
      onClick={onBtnClick}
    >
      <span className="font-ta-v2-global-body-large">{btnText}</span>
      {direction && (
        <span className="ta-v2-cta-btn-icon">
          {(!isLayoutRightToLeft && direction === 'left') || (isLayoutRightToLeft && direction === 'right') ? (
            <ArrowLeft className={cn({ isDark })} />
          ) : (
            <ArrowRight className={cn({ isDark })} />
          )}
        </span>
      )}
    </button>
  );
};
