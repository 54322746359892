import cn from 'classnames';

import { ReactComponent as Star } from '@app/pmi/src/assets/media/V2/star.svg';
import { ReactComponent as Trending } from '@app/pmi/src/assets/media/V2/trending.svg';
import { ReactComponent as Heart } from '@app/pmi/src/assets/media/V2/v2_product_match_heart.svg';
import { ReactComponent as Thumb } from '@app/pmi/src/assets/media/V2/v2_product_match_thumb.svg';

import { TechnicalCaptionIcon } from '@components/pmi/src/organisms/Results/RecommendedProduct/RecommendedProduct';

interface Props {
  icon: TechnicalCaptionIcon;
  text: string;
  isThreeProductsShown?: boolean;
}

export const TechnicalCaption = ({ icon, text, isThreeProductsShown }: Props) => {
  const getCurrentIcon = iconName => {
    let currentIcon;

    switch (iconName) {
      case 'thumb':
        currentIcon = <Thumb />;
        break;
      case 'heart':
        currentIcon = <Heart />;
        break;
      case 'star':
        currentIcon = <Star />;
        break;
      case 'trending':
        currentIcon = <Trending />;
        break;
      default:
    }

    return { currentIcon };
  };

  return (
    <div
      className={cn('ta-v2-technical-caption-cover', {
        isThreeProductsShown,
      })}
    >
      <span className="ta-v2-technical-caption-icon">{getCurrentIcon(icon).currentIcon}</span>
      <span className="ta-v2-technical-caption-text font-ta-v2-global-hint-text-medium">{text}</span>
    </div>
  );
};
