import cn from 'classnames';
import { Dispatch, SetStateAction, useLayoutEffect, useRef } from 'react';

interface Props {
  answerText: string;
  answerVideo: string;
  className?: string;
  answerId: string;
  maxAnswersChoices: number;
  setAnswersArray: Dispatch<SetStateAction<string[]>>;
  answersArray: string[];
  currentPrimaryColor: string;
}

export const AnswerItem = ({
  answerText,
  answerVideo,
  className,
  answerId,
  setAnswersArray,
  answersArray = [],
  maxAnswersChoices,
  currentPrimaryColor,
}: Props) => {
  const isSelected = answersArray.includes(answerId);

  const handleClick = () => {
    if (maxAnswersChoices >= 2 && answersArray.length !== maxAnswersChoices && !answersArray.includes(answerId)) {
      setAnswersArray(current => [...current, answerId]);
    }

    if (maxAnswersChoices === 1) {
      setAnswersArray([answerId]);
    }

    if (maxAnswersChoices === 1 && answersArray.length > 0 && answersArray[0] !== answerId) {
      setAnswersArray([answerId]);
    }

    if (answersArray.includes(answerId)) {
      setAnswersArray(current => current.filter(currentId => currentId !== answerId));
    }
  };

  const videoRef = useRef(null);

  useLayoutEffect(() => {
    if (answersArray.length > 0 && !isSelected && videoRef?.current) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
  }, [answersArray.length, isSelected]);

  const isCanClick = (answersArray.length <= maxAnswersChoices && maxAnswersChoices >= 2) || maxAnswersChoices === 1;

  return (
    <button
      className={cn('ta-v2-qds8005-answerItem-btn', className, {
        isSelected,
      })}
      onClick={
        isCanClick
          ? () => {
              handleClick();
            }
          : null
      }
    >
      <div
        className="ta-v2-qds8005-answerItem-btn-video-cover"
        style={{ backgroundColor: isSelected && currentPrimaryColor }}
      >
        <video ref={videoRef} autoPlay loop muted playsInline className="ta-v2-qds8005-answerItem-btn-video">
          <source src={answerVideo} type="video/mp4" />
        </video>
      </div>
      <div
        className="ta-v2-qds8005-answerItem-btn-text-cover font-ta-v2-super-paragraph-small"
        style={{ backgroundColor: isSelected && currentPrimaryColor }}
      >
        {answerText}
      </div>
    </button>
  );
};
