import cn from 'classnames';

import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import { LocaleFragment } from '@lib/tools/locale/views/LocaleFragment';

interface Props {
  isLight?: boolean;
}

export const MentholDisclaimer = ({ isLight = false }: Props) => {
  return (
    <div
      className={cn('ta-v2-menthol-disclaimer font-ta-v2-global-body-large', {
        'light-color': isLight,
      })}
    >
      <LocaleFragment message={localeV2.result.mentholDisclaimer} />
    </div>
  );
};
