import StartBtnBg from '@app/pmi/src/assets/media/crafted/botanicals_start_btn_bg.png';

import { localeCrafted } from '@lib/tools/locale/source/pmi/crafted';
import { LocaleFragment } from '@lib/tools/locale/views/LocaleFragment';

interface Props {
  backgroundImage: string;
  backgroundVideo: string;
  productLogo: string;
  onClick: () => void;
}

export const LandingPage = ({ backgroundImage, backgroundVideo, productLogo, onClick }: Props) => {
  return (
    <div className="ta-botanicals-landing-page" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="ta-botanicals-landing-overlay" />
      <div className="ta-botanicals-landing-page-content-wrapper">
        <div className="ta-botanicals-landing-page-top-block">
          <img alt="Logo" className="ta-botanicals-landing-page-logo" src={productLogo} />
          <div className="ta-botanicals-landing-video-overlay" />
          <video autoPlay loop muted playsInline className="ta-botanicals-landing-page-video-bg">
            <source src={backgroundVideo} type="video/mp4" />
          </video>
        </div>
        <img alt="Logo" className="ta-botanicals-landing-page-logo-desc" src={productLogo} />
        <div className="ta-botanicals-landing-page-description">
          <div className="ta-botanicals-landing-page-description-text">
            <LocaleFragment message={localeCrafted.landing.title} />
          </div>
          <div className="ta-botanicals-landing-page-description-text">
            <LocaleFragment message={localeCrafted.landing.subtitle} />
          </div>
          <div className="ta-botanicals-landing-page-description-text">
            <LocaleFragment message={localeCrafted.landing.help} />
          </div>
        </div>
      </div>
      <div className="ta-botanicals-landing-page-start-btn-cover">
        <button
          className="ta-botanicals-landing-page-start-btn"
          style={{ backgroundImage: `url(${StartBtnBg})` }}
          onClick={onClick}
        >
          <p className="ta-botanicals-landing-page-start-btn-text">
            <LocaleFragment message={localeCrafted.landing.ctaStart} />
          </p>
        </button>
      </div>
    </div>
  );
};
